import { messagetype } from 'types/inappnotification.type'

export const openNotification = (
     api: any,
     messageNotification: messagetype,
     setGotNotification: (gotNotification: boolean) => void
) => {
     api.info({
          message: messageNotification.title,
          description: messageNotification.body,
          duration: 8,
          placement: 'topRight',
          top: 200
     })

     setGotNotification(false)
}
