import { RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'
import { companySizeType, locationTypes } from 'types/constant.types'

export const companySizeList: companySizeType[] = [
     { label: '0-01 employees', value: '0-01 employees' },
     { label: '02-10 employees', value: '02-10 employees' },
     { label: '11-50 employees', value: '11-50 employees' },
     { label: '51-200 employees', value: '51-200 employees' },
     { label: '201-500 employees', value: '201-500 employees' },
     { label: '501-1,000 employees', value: '501-1,000 employees' },
     { label: '1,001-5,000 employees', value: '1,001-5,000 employees' },
     { label: '5,001-10,000 employees', value: '5,001-10,000 employees' },
     { label: '10,001+ employees', value: '10,001+ employees' }
]

export const locationList: locationTypes[] = [
     { label: 'Aberdeen', value: 'Aberdeen' },
     { label: 'Armagh', value: 'Armagh' },
     { label: 'Bangor', value: 'Bangor' },
     { label: 'Bath', value: 'Bath' },
     { label: 'Belfast', value: 'Belfast' },
     { label: 'Birmingham', value: 'Birmingham' },
     { label: 'Bradford', value: 'Bradford' },
     { label: 'Brighton and Hove', value: 'Brighton and Hove' },
     { label: 'Bristol', value: 'Bristol' },
     { label: 'Cambridge', value: 'Cambridge' },
     { label: 'Canterbury', value: 'Canterbury' },
     { label: 'Cardiff', value: 'Cardiff' },
     { label: 'Carlisle', value: 'Carlisle' },
     { label: 'Chelmsford', value: 'Chelmsford' },
     { label: 'Chester', value: 'Chester' },
     { label: 'Chichester', value: 'Chichester' },
     { label: 'Coventry', value: 'Coventry' },
     { label: 'Derby', value: 'Derby' },
     { label: 'Dundee', value: 'Dundee' },
     { label: 'Durham', value: 'Durham' },
     { label: 'Edinburgh', value: 'Edinburgh' },
     { label: 'Ely', value: 'Ely' },
     { label: 'Exeter', value: 'Exeter' },
     { label: 'Glasgow', value: 'Glasgow' },
     { label: 'Gloucester', value: 'Gloucester' },
     { label: 'Hereford', value: 'Hereford' },
     { label: 'Inverness', value: 'Inverness' },
     { label: 'Kingston upon Hull', value: 'Kingston upon Hull' },
     { label: 'Lancaster', value: 'Lancaster' },
     { label: 'Leeds', value: 'Leeds' },
     { label: 'Leicester', value: 'Leicester' },
     { label: 'Lichfield', value: 'Lichfield' },
     { label: 'Lincoln', value: 'Lincoln' },
     { label: 'Lisburn', value: 'Lisburn' },
     { label: 'Liverpool', value: 'Liverpool' },
     { label: 'London', value: 'London' },
     { label: 'Londonderry', value: 'Londonderry' },
     { label: 'Manchester', value: 'Manchester' },
     { label: 'Newcastle upon Tyne', value: 'Newcastle upon Tyne' },
     { label: 'Newport', value: 'Newport' },
     { label: 'Newry', value: 'Newry' },
     { label: 'Norwich', value: 'Norwich' },
     { label: 'Nottingham', value: 'Nottingham' },
     { label: 'Oxford', value: 'Oxford' },
     { label: 'Perth', value: 'Perth' },
     { label: 'Peterborough', value: 'Peterborough' },
     { label: 'Plymouth', value: 'Plymouth' },
     { label: 'Portsmouth', value: 'Portsmouth' },
     { label: 'Preston', value: 'Preston' },
     { label: 'Ripon', value: 'Ripon' },
     { label: 'Salford', value: 'Salford' },
     { label: 'Salisbury', value: 'Salisbury' },
     { label: 'Sheffield', value: 'Sheffield' },
     { label: 'Southampton', value: 'Southampton' },
     { label: 'St Albans', value: 'St Albans' },
     { label: 'St Asaph (Llanelwy)', value: 'St Asaph (Llanelwy)' },
     { label: 'St Davids', value: 'St Davids' },
     { label: 'Stirling', value: 'Stirling' },
     { label: 'Stoke-on-Trent', value: 'Stoke-on-Trent' },
     { label: 'Sunderland', value: 'Sunderland' },
     { label: 'Swansea', value: 'Swansea' },
     { label: 'Truro', value: 'Truro' },
     { label: 'Wakefield', value: 'Wakefield' },
     { label: 'Wells', value: 'Wells' },
     { label: 'Westminster', value: 'Westminster' },
     { label: 'Winchester', value: 'Winchester' },
     { label: 'Wolverhampton', value: 'Wolverhampton' },
     { label: 'Worcester', value: 'Worcester' },
     { label: 'York', value: 'York' }
]

export const weekArrayData = [
     { id: 1, value: false, key: 'sunday', label: 'Sunday' },
     { id: 2, value: false, key: 'monday', label: 'Monday' },
     { id: 3, value: false, key: 'tuesday', label: 'Tuesday' },
     { id: 4, value: false, key: 'wednesday', label: 'Wednesday' },
     { id: 5, value: false, key: 'thursday', label: 'Thursday' },
     { id: 6, value: false, key: 'friday', label: 'Friday' },
     { id: 7, value: false, key: 'saturday', label: 'Saturday' }
]

export const requirements = [
     {
          title: 'DBS check required',
          id: '1'
     },
     {
          title: 'Eligible to international student',
          id: '2'
     },
     {
          title: 'In - app interview',
          id: '3'
     }
]

export const bioVideoTips = [
     {
          title: 'When you apply for a job, the employer will review your Bio Video to learn about you.'
     },
     {
          title: 'You will have a default Bio Video saved to your profile, which you can use to apply to any job.'
     },
     {
          title: 'You can also record a fresh video for a particular job application, if you wish.'
     },
     {
          title: 'This video is only 60-seconds.'
     },
     {
          title: 'The aim is to introduce yourself in a relaxed yet clear manner'
     },
     {
          title: '...and to provide an overview of your experience and / or ambitions.'
     }
]

export const uploadFolder = {
     Profile: 'profile',
     BioVideo: 'biovideo',
     Interview: 'interview',
     Documents: 'document'
}
export const uploadedBy = {
     jobSeeker: 'jobseeker',
     employer: 'employer'
}

export const disabledDate: RangePickerProps['disabledDate'] = (current) => {
     // Can not select days before today and today
     return current && current < dayjs().endOf('day')
}

export const commonFilterOption = (input: string, option?: { label: string; value: string }) =>
     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
