import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { message } from 'antd'
import { getAccessToken } from 'awsGetToken'
import progressStore from 'hooks/useSyncStore'

const jobs_baseURL = process.env.REACT_APP_API_URL_JOBSEEKER
const emp_baseURL = process.env.REACT_APP_API_URL_EMPLOYER
// const userToken = localStorage.getItem('token')

interface APIOptions extends AxiosRequestConfig {
     url: string | undefined
     method: string | undefined
     data?: any
}
export const getAccessTokenFromAWS = async () => {
     const id: any = await getAccessToken(true)
     return id.jwtToken
}

export class apiRequest {
     public static async jobseekerRequest({ ...options }: APIOptions) {
          progressStore.startProgress(30)
          const token = await getAccessTokenFromAWS()
          const client = axios.create({ baseURL: jobs_baseURL })

          client.defaults.headers.common = {
               'content-type': 'application/json',
               Authorization: `Bearer ${token}`
          }
          const onSuccess = (response: AxiosResponse) => {
               progressStore.startProgress(100)
               return response.data
          }
          const onError = (error: AxiosError | any) => {
               if (error?.response?.data?.statusCode == 401) {
                    message.error('Session token is expired')
                    localStorage.clear()
                    window.location.replace('/auth/join-us')
               } else if (error.code === 'ERR_NETWORK') {
                    message.error('No internet connection')
               } else if (error.response.data.statusCode == 403) {
                    const newErrr: object = error?.response?.data
                         ? error?.response?.data
                         : JSON.parse(error?.request?.response)
                    throw newErrr
               } else if (error.response.data.statusCode == 400) {
                    const newErrr: object = error?.response?.data
                         ? error?.response?.data
                         : JSON.parse(error?.request?.response)
                    throw newErrr
               } else if (error?.response?.data?.statusCode == 500) {
                    const newErrr: object = error?.response?.data
                         ? error?.response?.data
                         : JSON.parse(error?.request?.response)
                    throw newErrr
               } else {
                    throw error
               }
               progressStore.startProgress(100)
          }
          return client(options).then(onSuccess).catch(onError)
     }

     public static async employerRequest({ ...options }: APIOptions) {
          progressStore.startProgress(30)
          const client = axios.create({
               baseURL: emp_baseURL
          })
          const token = await getAccessTokenFromAWS()
          client.defaults.headers.common = {
               'content-type': 'application/json',
               Authorization: `Bearer ${token}`
          }
          const onSuccess = (response: AxiosResponse) => {
               progressStore.startProgress(100)
               return response.data
          }
          const onError = (error: AxiosError | any) => {
               if (error?.response?.data?.statusCode == 401) {
                    message.error('Session token is expired')
                    localStorage.clear()
                    window.location.replace('/auth/join-us')
               } else if (error.code === 'ERR_NETWORK') {
                    message.error('No internet connection')
               } else if (error.response.data.statusCode == 403) {
                    const newErrr: object = error?.response?.data
                         ? error?.response?.data
                         : JSON.parse(error?.request?.response)
                    throw newErrr
               } else if (error.response.data.statusCode == 400) {
                    const newErrr: object = error?.response?.data
                         ? error?.response?.data
                         : JSON.parse(error?.request?.response)
                    throw newErrr
               } else if (error?.response?.data?.statusCode == 500) {
                    const newErrr: object = error?.response?.data
                         ? error?.response?.data
                         : JSON.parse(error?.request?.response)
                    throw newErrr
               } else {
                    throw error
               }
               progressStore.startProgress(100)
          }

          return client(options).then(onSuccess).catch(onError)
     }
}
