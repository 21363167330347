export const awsJobSeeker = {
     aws_project_region: process.env.REACT_APP_AWS_JS_USER_REGION,
     aws_cognito_region: process.env.REACT_APP_AWS_JS_USER_REGION,
     aws_user_pools_id: process.env.REACT_APP_AWS_JS_USER_POOL_ID,
     aws_user_pools_web_client_id: process.env.REACT_APP_AWS_JS_USER_CLIENT_ID,
     oauth: {}
}

export const awsEmployer = {
     aws_project_region: process.env.REACT_APP_AWS_EMP_USER_REGION,
     aws_cognito_region: process.env.REACT_APP_AWS_EMP_USER_REGION,
     aws_user_pools_id: process.env.REACT_APP_AWS_EMP_USER_POOL_ID,
     aws_user_pools_web_client_id: process.env.REACT_APP_AWS_EMP_USER_CLIENT_ID,
     oauth: {}
}
