import React, { ReactNode, useRef } from 'react'
import { ConfigProvider, App } from 'antd'

type Props = {
     children: ReactNode
}

export default function index({ children }: Props) {
     const modalContainerRef = useRef<HTMLDivElement>(null)
     return (
          <ConfigProvider
               theme={{
                    token: {
                         colorPrimary: '#3456FF',
                         fontFamily: 'Gilroy'
                    },
                    components: {
                         Button: {
                              borderRadius: 24,
                              boxShadow: '0px 16px 60px 0px rgba(44, 41, 41, 0.08)',
                              controlHeight: 46,
                              fontSize: 18,
                              fontSizeLG: 17,
                              fontSizeSM: 15,
                              fontSizeXL: 18,
                              fontWeightStrong: 600
                         },
                         Input: {
                              borderRadius: 0,
                              colorInfoBorderHover: 'none',
                              colorPrimary: '#000000',
                              colorBgBase: '#00000000',
                              colorBgContainer: '#00000000',
                              fontFamily: 'Gilroy-Semibold',
                              fontSize: 14,
                              fontWeightStrong: 500,
                              lineHeight: 1,
                              colorTextPlaceholder: '#B8B8B8'
                         },
                         InputNumber: {
                              borderRadius: 0,
                              colorInfoBorderHover: 'none',
                              colorPrimary: '#000000',
                              colorBgBase: '#00000000',
                              colorBgContainer: '#00000000',
                              fontFamily: 'Gilroy-Semibold',
                              fontSize: 14,
                              fontWeightStrong: 500,
                              lineHeight: 1,
                              colorTextPlaceholder: '#B8B8B8'
                         },
                         Divider: {
                              colorBgBase: '#B8B8B8',
                              colorBorder: '#B8B8B8'
                         },
                         Select: {
                              borderRadius: 0,
                              colorInfoBorderHover: 'none',
                              colorPrimary: '#000000',
                              fontSize: 14,
                              fontWeightStrong: 500,
                              lineHeight: 1,
                              fontFamily: 'Gilroy-Semibold',
                              colorTextPlaceholder: '#B8B8B8',
                              colorBgBase: '#00000000',
                              colorBgContainer: '#00000000'
                         },
                         DatePicker: {
                              borderRadius: 0,
                              colorInfoBorderHover: 'none',
                              colorPrimary: '#3456ff',
                              fontSize: 14,
                              fontFamily: 'Gilroy-Semibold',
                              fontWeightStrong: 500,
                              lineHeight: 1,
                              colorTextPlaceholder: '#B8B8B8',
                              colorBgBase: '#00000000',
                              colorBgContainer: '#00000000'
                         },
                         Checkbox: {
                              colorBgBase: '#00000000',
                              colorBgContainer: '#00000000',
                              borderRadius: 2,
                              colorBorder: '#3456FF45'
                         }
                    }
               }}
               getPopupContainer={() => modalContainerRef.current as HTMLElement}>
               <div ref={modalContainerRef}>
                    <App>{children}</App>
               </div>
          </ConfigProvider>
     )
}
