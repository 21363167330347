/* eslint-disable no-undef */
import firebase from 'firebase/compat/app'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './firebaseConfig'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

let messaging: any

export const fetchToken = (setTokenData: any, sendTokenDetails: (token: string | null) => void) => {
     return getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY_ID
     })
          .then((currentToken) => {
               console.log(currentToken)
               if (currentToken) {
                    setTokenData({ tokenFound: true, token: currentToken })
                    sendTokenDetails(currentToken)
                    // Track the token -> client mapping, by sending to backend server
                    // show on the UI that permission is secured
               } else {
                    setTokenData({ tokenFound: false, token: null })
                    // shows on the UI that permission is required
               }
          })
          .catch((err) => {
               console.log('An error occurred while retrieving token. ', err)
               // catch error while creating client token
          })
}

export const fetchSignInToken = (setTokenData: any) => {
     return getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY_ID
     })
          .then((currentToken) => {
               console.log(currentToken)
               if (currentToken) {
                    setTokenData({ tokenFound: true, token: currentToken })
               } else {
                    setTokenData({ tokenFound: false, token: null })
               }
          })
          .catch((err) => {
               console.log('An error occurred while retrieving token. ', err)
          })
}

export const onMessageListener = () =>
     new Promise((resolve) => {
          onMessage(messaging, (payload) => {
               resolve(payload)
          })
     })

// if (firebase) console.log(firebase.apps)
if (!firebase.apps.length) {
     const firebaseApp = initializeApp(firebaseConfig)
     messaging = getMessaging(firebaseApp)
     // console.log(firebase.apps.length)
} else {
     firebase.app()
}
