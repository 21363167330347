import { apiRequest } from 'utils/axios'
import { EMPLOYER_APIS, JOBSEEKER_APIS } from 'utils/eps'

// JOBSEEKER API REQUESTS =====>
export const profile_get_jobSeeker = () => {
     return apiRequest.jobseekerRequest({ url: JOBSEEKER_APIS.PROFILE_GET, method: 'get' })
}

// EMPLOYER API REQUESTS =====>
export const profile_get_employer = () => {
     return apiRequest.employerRequest({ url: EMPLOYER_APIS.EMP_PROFILE_GET, method: 'get' })
}
// PROFILE API REQUESTS =====>
export const USER_PROFILE_UPDATE = (values: any) => {
     return apiRequest.jobseekerRequest({ url: JOBSEEKER_APIS.USER_PROFILE, method: 'patch', data: values })
}

// EMPLOYER PROFILE API REQUESTS =====>
export const EMPLOYER_PROFILE_UPDATE = (values: any) => {
     return apiRequest.employerRequest({
          url: EMPLOYER_APIS.PATCH_EMPLOYER_PROFILE,
          method: 'patch',
          data: values
     })
}
export const GET_EMP_SECTOR_DETAILS = () => {
     return apiRequest.employerRequest({ url: EMPLOYER_APIS.EMP_SECTORS, method: 'get' })
}
// EMPLOYER USER CHECK
export const check_employer_user = () => {
     return apiRequest.employerRequest({ url: EMPLOYER_APIS.EMP_CHECK_USER, method: 'get' })
}

export const DELETE_QUESTION_QB = (id: string) => {
     return apiRequest.employerRequest({ url: EMPLOYER_APIS.EMP_DELETE_QUE_FROM_QB(id), method: 'delete' })
}
