import { useMutation, useQuery } from 'react-query'
import {
     DELETE_QUESTION_QB,
     EMPLOYER_PROFILE_UPDATE,
     GET_EMP_SECTOR_DETAILS,
     USER_PROFILE_UPDATE,
     check_employer_user,
     profile_get_employer,
     profile_get_jobSeeker
} from './api-helper'
import { message } from 'antd'

const signInType = localStorage.getItem('signInType')
// JOBSEEKER API REQUESTS =====>
export const useGetJobSeekerProfile = (navigate: any, sType?: string | null) =>
     useQuery(['PROFILE_JOBSEEKER'], () => profile_get_jobSeeker(), {
          keepPreviousData: true,
          enabled: sType && sType == 'jobseeker' ? true : signInType == 'jobseeker',
          onSuccess: (data) => {
               if (data?.data?.isUser == false) {
                    message.warning('Please complete your profile')
                    navigate(`/auth/sign-up/complete/jobseeker`, { state: { profile: 'inComplete' } })
               } else if (data?.data?.userProfile[0]?.status == 'Pending') {
                    navigate(`/verification-pending`)
               } else if (data?.data?.userProfile[0]?.status == 'Rejected') {
                    navigate(`/verification-rejected`)
               }
          }
     })

// EMPLOYER API REQUESTS =====>
export const useGetEmployerProfile = (sType?: string | null) =>
     useQuery(['PROFILE_EMPLOYER'], () => profile_get_employer(), {
          keepPreviousData: true,
          enabled: sType && sType == 'employer' ? true : signInType == 'employer',
          onSuccess: (response) => {
               localStorage.setItem('employerId', response?._id)
          }
     })
export const useEmpCheckUser = (navigate: any, sType?: string | null) =>
     useQuery(['PROFILE_CHECK_USER'], () => check_employer_user(), {
          keepPreviousData: true,
          enabled: sType && sType == 'employer' ? true : signInType == 'employer',
          onSuccess: (data) => {
               if (data?.isUser == false) {
                    message.warning('Please complete your profile')
                    navigate(`/auth/sign-up/complete/employer`, { state: { profile: 'inComplete' } })
               } else if (data?.isUser == true && data?.status == 'Pending') {
                    navigate(`/verification-pending`)
               } else if (data?.isUser == true && data?.status == 'Rejected') {
                    navigate(`/verification-rejected`)
               }
          }
     })
export const useGetEmployerSector = () =>
     useQuery(['EMPLOYER_SECTOR'], () => GET_EMP_SECTOR_DETAILS(), {
          keepPreviousData: true
     })
export const UPDATE_USER_PROFILE = () => useMutation((values: any) => USER_PROFILE_UPDATE(values))
export const UPDATE_EMPLOYER_PROFILE = () =>
     useMutation((payload: { values: any }) => EMPLOYER_PROFILE_UPDATE(payload.values))

export const DELETE_QUE_FROM_QB = () => useMutation((id: string) => DELETE_QUESTION_QB(id))
