import type { InputRef } from 'antd'
import React, { ReactNode, createContext, useRef, useState } from 'react'
import { paginationType } from 'types/constant.types'
import { jobSearchDataType } from 'types/jobSearch.types'
import { EducationType, WeekType, availabilityType, workDetailsType } from 'types/onBoarding.types'
import { weekArrayData } from 'utils/constants'

type Props = {
     children: ReactNode
}

export const CommmonStateContext = createContext<{
     logOutOpen: boolean
     setLogOutOpen: (logOutOpen: boolean) => void
     deleteOpen: boolean
     setDeleteOpen: (deleteOpen: boolean) => void
     current: number
     setCurrent: (current: number) => void
     isPastExperience: boolean
     setIsPastExperience: (isPastExperience: boolean) => void
     modalOpen: boolean
     setModalOpen: (modalOpen: boolean) => void
     achievementValue: string
     setAchievementValue: (achievementValue: string) => void
     achievements: string[]
     setAchievements: (achievements: string[]) => void
     achievementsRef: any
     educationModal: boolean
     setEducationModal: (educationModal: boolean) => void
     workHourModal: boolean
     setWorkHourModal: (educationModal: boolean) => void
     weekSwitch: WeekType[]
     setWeekSwitch: (weekSwitch: WeekType[]) => void
     awardValue: string
     setAwardValue: (awardValue: string) => void
     awards: string[]
     setAwards: (awards: string[]) => void
     awardsRef: any
     subjectValue: string
     setSubjectValue: (awardValue: string) => void
     subjects: string[]
     setSubjects: (awards: string[]) => void
     subjectsRef: any
     aLevelSubjectValue: string
     setALevelSubjectValue: (awardValue: string) => void
     aLevelSubjects: string[]
     setALevelSubjects: (awards: string[]) => void
     aLevelSubjectsRef: any
     qualificationValue: string
     setQualificationValue: (qualificationValue: string) => void
     qualifications: string[]
     setQualifications: (qualifications: string[]) => void
     qualificationsRef: any
     workDetail: workDetailsType
     setWorkDetail: (workDetail: workDetailsType) => void
     educationDetails: EducationType
     setEducationDetails: (educationDetails: EducationType) => void
     availability: availabilityType
     setAvailability: (availability: availabilityType) => void
     noWebCamOpen: boolean
     setNoWebCamOpen: (noWebCamOpen: boolean) => void
     jobContent: jobSearchDataType[]
     setJobContent: (jobContent: jobSearchDataType[]) => void
     jobSearchPagination: paginationType
     setJobSearchPagination: (jobSearchPagination: paginationType) => void
     jobCartPagination: paginationType
     setJobCartPagination: (jobCartPagination: paginationType) => void
     applicationPagination: paginationType
     setApplicationPagination: (applicationPagination: paginationType) => void
     interviewTime: boolean
     setInterviewTime: (interviewTime: boolean) => void

     conditionalModal: boolean
     setConditionalModal: (conditionalModal: boolean) => void
     declineModal: boolean
     setDeclineModal: (declineModal: boolean) => void
     feedbackModal: boolean
     setFeedbackModal: (feedbackModal: boolean) => void
     videoInterviewModal: boolean
     setVideoInterviewModal: (videoInterview: boolean) => void
     exhausted: boolean
     setExhausted: (exhausted: boolean) => void
     empPremiumModal: boolean
     setEmpPremiumModal: (videoInterview: boolean) => void
     defaultJSearchActiveKey: string
     setDefaultJSearchActiveKey: (defaultJSearchActiveKey: string) => void
     defaultJCartActiveKey: string
     setDefaultJCartActiveKey: (defaultJCartActiveKey: string) => void
     unReadNotification: number
     setUnReadNotification: (unReadNotification: number) => void
}>({
     logOutOpen: false,
     setLogOutOpen: (logOutOpen: boolean) => logOutOpen,
     deleteOpen: false,
     setDeleteOpen: (deleteOpen: boolean) => deleteOpen,
     current: 0,
     setCurrent: (current: number) => current,
     isPastExperience: false,
     setIsPastExperience: (isPastExperience: boolean) => isPastExperience,
     modalOpen: false,
     setModalOpen: (modalOpen: boolean) => modalOpen,
     achievementValue: '',
     setAchievementValue: (achievementValue: string) => achievementValue,
     achievements: [],
     setAchievements: (achievements: string[]) => achievements,
     achievementsRef: null,
     educationModal: false,
     setEducationModal: (modalOpen: boolean) => modalOpen,
     workHourModal: false,
     setWorkHourModal: (modalOpen: boolean) => modalOpen,
     weekSwitch: [...weekArrayData],
     setWeekSwitch: (weekSwitch: WeekType[]) => weekSwitch,
     awardValue: '',
     setAwardValue: (achievementValue: string) => achievementValue,
     awards: [],
     setAwards: (achievements: string[]) => achievements,
     awardsRef: null,
     subjectValue: '',
     setSubjectValue: (subjectValue: string) => subjectValue,
     subjects: [],
     setSubjects: (subjects: string[]) => subjects,
     subjectsRef: null,
     aLevelSubjectValue: '',
     setALevelSubjectValue: (aLevelSubjectValue: string) => aLevelSubjectValue,
     aLevelSubjects: [],
     setALevelSubjects: (aLevelsubjects: string[]) => aLevelsubjects,
     aLevelSubjectsRef: null,
     qualificationValue: '',
     setQualificationValue: (qualificationValue: string) => qualificationValue,
     qualifications: [],
     setQualifications: (qualifications: string[]) => qualifications,
     qualificationsRef: null,
     workDetail: {
          achievements: [],
          currentlyWorking: false,
          employer: {
               name: '',
               _id: ''
          },
          endDate: [null, null],
          sector: {
               name: '',
               _id: ''
          },
          startDate: [null, null],
          title: '',
          _id: ''
     },
     setWorkDetail: (workDetail: workDetailsType) => workDetail,
     educationDetails: {
          _id: '',
          internshipPlacement: '',
          userId: '',
          schoolName: '',
          universityBoard: '',
          nameOfInstitution: '',
          startDate: [null, null],
          endDate: [null, null],
          achievements: [],
          subjects: [],
          aLevels: [],
          alternativeQualifications: [],
          fieldOfStudy: {
               _id: '',
               name: ''
          },
          degree: {
               _id: '',
               name: ''
          },
          university: {
               _id: '',
               name: ''
          },
          currentlyStudying: false,
          predictedGrade: false,
          isOutsideUk: false
     },
     setEducationDetails: (educationDetails: EducationType) => educationDetails,
     availability: { _id: '', times: [], text: [], userId: '' },
     setAvailability: (availability: availabilityType) => availability,
     noWebCamOpen: false,
     setNoWebCamOpen: (noWebCamOpen: boolean) => noWebCamOpen,
     jobContent: [
          {
               _id: '',
               title: '',
               description: '',
               location: '',
               salary: 0,
               salaryUnit: '',
               currencyIcon: '',
               closingDate: '',
               cal: {
                    interest: 0,
                    skills: 0
               },
               workPlacePhotos: '',
               employerName: '',
               logo: '',
               sector: '',
               strongMatch: 0,
               easyApply: false,
               contractLength: '',
               employerBio: '',
               hoursPerWeek: 0,
               isDbsCheck: false,
               openToInternationalStudents: false,
               isInterviewRecorded: false,
               isSaved: false,
               isApplied: false,
               skillsRequired: [],
               jobcart: [
                    {
                         _id: ''
                    }
               ]
          }
     ],
     jobSearchPagination: {
          pageSize: 5,
          current: 1,
          total: 0
     },
     setJobSearchPagination: (jobSearchPagination: paginationType) => jobSearchPagination,
     jobCartPagination: {
          pageSize: 5,
          current: 1,
          total: 0
     },
     setJobCartPagination: (jobCartPagination: paginationType) => jobCartPagination,
     applicationPagination: {
          pageSize: 5,
          current: 1,
          total: 0
     },
     setApplicationPagination: (applicationPagination: paginationType) => applicationPagination,
     setJobContent: (jobContent: jobSearchDataType[]) => jobContent,
     interviewTime: false,
     setInterviewTime: (interviewTime: boolean) => interviewTime,

     conditionalModal: false,
     setConditionalModal: (conditionalModal: boolean) => conditionalModal,
     declineModal: false,
     setDeclineModal: (declineModal: boolean) => declineModal,
     feedbackModal: false,
     setFeedbackModal: (feedbackModal: boolean) => feedbackModal,
     videoInterviewModal: false,
     setVideoInterviewModal: (videoInterviewModal: boolean) => videoInterviewModal,
     exhausted: false,
     setExhausted: (exhausted: boolean) => exhausted,
     empPremiumModal: false,
     setEmpPremiumModal: (empPremiumModal: boolean) => empPremiumModal,
     defaultJSearchActiveKey: '',
     setDefaultJSearchActiveKey: (defaultJSearchActiveKey: string) => defaultJSearchActiveKey,
     defaultJCartActiveKey: '',
     setDefaultJCartActiveKey: (defaultJCartActiveKey: string) => defaultJCartActiveKey,
     unReadNotification: 0,
     setUnReadNotification: (unReadNotification: number) => unReadNotification
})
export default function CommonStates({ children }: Props): JSX.Element {
     const [logOutOpen, setLogOutOpen] = useState<boolean>(false)
     const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
     const [exhausted, setExhausted] = useState<boolean>(false)
     const [current, setCurrent] = useState<number>(0)
     //experience states
     const [isPastExperience, setIsPastExperience] = useState(false)
     const [modalOpen, setModalOpen] = useState<boolean>(false)
     const [noWebCamOpen, setNoWebCamOpen] = useState<boolean>(false)
     const [achievementValue, setAchievementValue] = useState<string>('')
     const [achievements, setAchievements] = useState<string[]>([])
     const [workDetail, setWorkDetail] = useState<workDetailsType>({
          achievements: [],
          currentlyWorking: false,
          employer: {
               name: '',
               _id: ''
          },
          endDate: [null, null],
          sector: {
               name: '',
               _id: ''
          },
          startDate: [null, null],
          title: '',
          _id: ''
     })
     const achievementsRef = useRef<InputRef>(null)

     // education states
     const [educationModal, setEducationModal] = useState<boolean>(false)

     //work hour states
     const [workHourModal, setWorkHourModal] = useState<boolean>(false)
     const [weekSwitch, setWeekSwitch] = useState<WeekType[]>([...weekArrayData])
     const [availability, setAvailability] = useState<availabilityType>({
          _id: '',
          times: [],
          text: [],
          userId: ''
     })
     const [awardValue, setAwardValue] = useState<string>('')
     const [awards, setAwards] = useState<string[]>([])
     const awardsRef = useRef<InputRef>(null)
     const [subjectValue, setSubjectValue] = useState<string>('')
     const [subjects, setSubjects] = useState<string[]>([])
     const subjectsRef = useRef<InputRef>(null)
     const [aLevelSubjectValue, setALevelSubjectValue] = useState<string>('')
     const [aLevelSubjects, setALevelSubjects] = useState<string[]>([])
     const aLevelSubjectsRef = useRef<InputRef>(null)
     const [qualificationValue, setQualificationValue] = useState<string>('')
     const [qualifications, setQualifications] = useState<string[]>([])
     const qualificationsRef = useRef<InputRef>(null)
     const [educationDetails, setEducationDetails] = useState<EducationType>({
          _id: '',
          internshipPlacement: '',
          userId: '',
          universityBoard: '',
          schoolName: '',
          nameOfInstitution: '',
          startDate: [null, null],
          endDate: [null, null],
          achievements: [],
          subjects: [],
          aLevels: [],
          alternativeQualifications: [],
          fieldOfStudy: {
               _id: '',
               name: ''
          },
          degree: {
               _id: '',
               name: ''
          },
          university: {
               _id: '',
               name: ''
          },
          currentlyStudying: false,
          predictedGrade: false,
          isOutsideUk: false
     })
     const [jobContent, setJobContent] = useState<jobSearchDataType[]>([
          {
               _id: '',
               title: '',
               description: '',
               location: '',
               salary: 0,
               salaryUnit: '',
               currencyIcon: '',
               closingDate: '',
               cal: {
                    interest: 0,
                    skills: 0
               },
               workPlacePhotos: '',
               employerName: '',
               logo: '',
               sector: '',
               strongMatch: 0,
               easyApply: false,
               employerBio: '',
               hoursPerWeek: 0,
               isDbsCheck: false,
               openToInternationalStudents: false,
               contractLength: '',
               isInterviewRecorded: false,
               isSaved: false,
               isApplied: false,
               skillsRequired: [],
               jobcart: [
                    {
                         _id: ''
                    }
               ]
          }
     ])
     const [jobSearchPagination, setJobSearchPagination] = useState<paginationType>({
          pageSize: 5,
          current: 1,
          total: 0
     })
     const [jobCartPagination, setJobCartPagination] = useState<paginationType>({
          pageSize: 5,
          current: 1,
          total: 0
     })
     const [applicationPagination, setApplicationPagination] = useState<paginationType>({
          pageSize: 5,
          current: 1,
          total: 0
     })
     // common state inteerview
     const [interviewTime, setInterviewTime] = useState<boolean>(false)

     const [conditionalModal, setConditionalModal] = useState<boolean>(false)
     const [declineModal, setDeclineModal] = useState<boolean>(false)
     const [feedbackModal, setFeedbackModal] = useState<boolean>(false)
     const [videoInterviewModal, setVideoInterviewModal] = useState<boolean>(false)
     const [empPremiumModal, setEmpPremiumModal] = useState<boolean>(false)

     const [defaultJSearchActiveKey, setDefaultJSearchActiveKey] = useState('')
     const [defaultJCartActiveKey, setDefaultJCartActiveKey] = useState('')
     const [unReadNotification, setUnReadNotification] = useState<number>(0)
     return (
          <React.Fragment>
               <CommmonStateContext.Provider
                    value={{
                         logOutOpen,
                         setLogOutOpen,
                         deleteOpen,
                         setDeleteOpen,
                         applicationPagination,
                         setApplicationPagination,
                         current,
                         setCurrent,
                         isPastExperience,
                         setIsPastExperience,
                         modalOpen,
                         setModalOpen,
                         setWeekSwitch,
                         weekSwitch,
                         achievementValue,
                         setAchievementValue,
                         achievements,
                         setAchievements,
                         achievementsRef,
                         educationModal,
                         setEducationModal,
                         setWorkHourModal,
                         workHourModal,
                         awardValue,
                         setAwardValue,
                         awards,
                         setAwards,
                         awardsRef,
                         subjectValue,
                         setSubjectValue,
                         subjects,
                         setSubjects,
                         subjectsRef,
                         aLevelSubjectValue,
                         setALevelSubjectValue,
                         aLevelSubjects,
                         setALevelSubjects,
                         aLevelSubjectsRef,
                         qualificationValue,
                         setQualificationValue,
                         qualifications,
                         setQualifications,
                         qualificationsRef,
                         workDetail,
                         setWorkDetail,
                         educationDetails,
                         setEducationDetails,
                         availability,
                         setAvailability,
                         setNoWebCamOpen,
                         noWebCamOpen,
                         setJobContent,
                         jobContent,
                         setJobSearchPagination,
                         jobSearchPagination,
                         setJobCartPagination,
                         jobCartPagination,
                         interviewTime,
                         setInterviewTime,
                         conditionalModal,
                         setConditionalModal,
                         declineModal,
                         setDeclineModal,
                         feedbackModal,
                         setFeedbackModal,
                         videoInterviewModal,
                         setVideoInterviewModal,
                         setExhausted,
                         exhausted,
                         empPremiumModal,
                         setEmpPremiumModal,
                         defaultJSearchActiveKey,
                         setDefaultJSearchActiveKey,
                         defaultJCartActiveKey,
                         setDefaultJCartActiveKey,
                         unReadNotification,
                         setUnReadNotification
                    }}>
                    {children}
               </CommmonStateContext.Provider>
          </React.Fragment>
     )
}
