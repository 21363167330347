import React, { Suspense, createContext, lazy, useEffect, useLayoutEffect, useState, useSyncExternalStore } from 'react'
import { Auth } from 'aws-amplify'
import './App.scss'
import AntdConfigProvider from 'components/cmpCommonComps/cmpAntdConfig'
import { awsEmployer, awsJobSeeker } from 'utils/cognitoConfig/awsConfig'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { isLoggedIn } from 'awsGetToken'
import CommonStates from 'components/cmpCommonComps/cmpCommonStates/CommonStates'
import SpinnerComp from 'components/cmpCommonComps/SpinnerComp'
import progressStore from 'hooks/useSyncStore'

const RequireAuth = lazy(() => import('utils/components/RequireAuth'))
const ProtectedRoutes = lazy(() => import('utils/components/ProtectedRoutes'))
const AuthRoutes = lazy(() => import('utils/components/AuthRoutes'))
const SplashScreen = lazy(() => import('components/cmpCommonComps/cmpSplashScreen'))

import { initializeApp } from 'firebase/app'
import { firebaseConfig } from 'utils/firebase/firebaseConfig'
import firebase from 'firebase/compat/app'
import { fetchToken, onMessageListener } from 'utils/firebase/firebase'
import { UPDATE_EMPLOYER_PROFILE, UPDATE_USER_PROFILE } from 'components/cmpDashboard/cmpProfile/apis/services'
import 'antd/dist/reset.css'
import LoadingBar from 'react-top-loading-bar'
import { message, notification } from 'antd'
import { openNotification } from 'utils/firebase/notification'
import { messagetype, notificationTypePayload } from 'types/inappnotification.type'

export const MainContext = createContext<{
     loading: boolean
     setLoading: (loading: boolean) => void
     loggedIn: null
     setLoggedIn: (loggedIn: any) => void
     tokenData: any
     setTokenData: (tokenData: any) => void
     awsType: string
     setAwsType: (awsType: string) => void
     sendTokenDetails: (token: string | null) => void
}>({
     loading: false,
     setLoading: (loading: boolean) => loading,
     loggedIn: null,
     setLoggedIn: (loggedIn: any) => loggedIn,
     tokenData: { tokenFound: false, token: null },
     setTokenData: (tokenData: any) => tokenData,
     awsType: 'jobseeker',
     setAwsType: (awsType: string) => awsType,
     sendTokenDetails: (token: string | null) => token
})
function App() {
     initializeApp(firebaseConfig)
     const pathname = window.location.pathname
     const selectedType = localStorage.getItem('signInType')
     const progress = useSyncExternalStore(progressStore.subscribe, progressStore.read)
     const [loading, setLoading] = useState(false)
     const [loggedIn, setLoggedIn] = useState<any>(null)
     const [tokenData, setTokenData] = useState({ tokenFound: false, token: null })
     const [gotNotification, setGotNotification] = useState<boolean>(false)
     const [messageNotification, setMessageNotification] = useState<messagetype>({ title: null, body: null })
     const [api, contextHolder] = notification.useNotification()
     const isSplashScreen = sessionStorage.getItem('splashVisible')
     const [splashScreen, setSplashScreen] = useState(!isSplashScreen ? true : false)
     const [awsType, setAwsType] = useState(selectedType ? selectedType : 'jobseeker')
     const { isLoading: empProfileLoading, mutateAsync: empProfileMutation } = UPDATE_EMPLOYER_PROFILE()
     const { isLoading: jsProfileLoading, mutateAsync: jsProfileMutation } = UPDATE_USER_PROFILE()

     const sendTokenDetails = async (token: string | null) => {
          if (selectedType === 'employer') {
               try {
                    await empProfileMutation({
                         values: {
                              webToken: token ? token : ''
                         }
                    })
               } catch (error: any) {
                    message.error(error?.message)
               }
          } else if (selectedType === 'jobseeker') {
               try {
                    await jsProfileMutation({ webToken: token ? token : '' })
               } catch (error: any) {
                    message.error(error?.message)
               }
          }
     }

     useLayoutEffect(() => {
          // Wait for 3 seconds
          const timer = setTimeout(() => {
               setSplashScreen(false)
               sessionStorage.setItem('splashVisible', 'visibled')
          }, 1000)

          return () => clearTimeout(timer)
     }, [])

     useEffect(() => {
          // Load the todos on mount
          Auth.configure(
               selectedType === 'employer' || awsType == 'employer'
                    ? {
                           region: awsEmployer.aws_cognito_region,
                           userPoolId: awsEmployer.aws_user_pools_id,
                           userPoolWebClientId: awsEmployer.aws_user_pools_web_client_id
                      }
                    : {
                           region: awsJobSeeker.aws_cognito_region,
                           userPoolId: awsJobSeeker.aws_user_pools_id,
                           userPoolWebClientId: awsJobSeeker.aws_user_pools_web_client_id
                      }
          )
     }, [awsType, selectedType])

     useEffect(() => {
          if (pathname == '/auth/sign-in/employer' || pathname == '/auth/sign-up/employer') {
               setAwsType('employer')
               localStorage.setItem('signInType', 'employer')
          } else if (pathname == '/auth/sign-in/jobseeker' || pathname == '/auth/sign-up/jobseeker') {
               setAwsType('jobseeker')
               localStorage.setItem('signInType', 'jobseeker')
          }
     }, [pathname, awsType])

     useEffect(() => {
          async function getSessionState() {
               const _loggedIn = await isLoggedIn()
               setLoggedIn(_loggedIn)
               setLoading(false)
          }
          getSessionState()
     }, [])

     useEffect(() => {
          if (!firebase.apps.length && loggedIn) {
               fetchToken(setTokenData, sendTokenDetails)
          }
     }, [loggedIn])

     onMessageListener()
          .then((payload: notificationTypePayload | any) => {
               setGotNotification(true)
               setMessageNotification({ title: payload.notification.title, body: payload.notification.body })
          })
          .catch((err) => console.log('failed: ', err))

     useEffect(() => {
          if (gotNotification) {
               if (!messageNotification.title && !messageNotification.body) return
               else if (messageNotification.title && messageNotification.body) {
                    openNotification(api, messageNotification, setGotNotification)
               }
          }
     }, [messageNotification])

     //splash Screen view
     if (splashScreen) return <SplashScreen />

     //Spinner view if extra activity
     if (loading && empProfileLoading && jsProfileLoading) return <SpinnerComp />

     return (
          <AntdConfigProvider>
               <MainContext.Provider
                    value={{
                         loading,
                         setLoading,
                         loggedIn,
                         setLoggedIn,
                         tokenData,
                         setTokenData,
                         awsType,
                         setAwsType,
                         sendTokenDetails
                    }}>
                    <Router>
                         <Routes>
                              <Route
                                   path="/auth/*"
                                   element={
                                        <Suspense fallback={<SpinnerComp />}>
                                             <AuthRoutes />
                                        </Suspense>
                                   }
                              />
                              <Route
                                   path="/*"
                                   element={
                                        <Suspense fallback={<SpinnerComp />}>
                                             <RequireAuth>
                                                  <Suspense fallback={<SpinnerComp />}>
                                                       <CommonStates>
                                                            <LoadingBar
                                                                 color={'#3456FF'}
                                                                 progress={progress}
                                                                 height={3}
                                                                 onLoaderFinished={() => progressStore.stopProgress()}
                                                                 shadow={true}
                                                            />
                                                            <ProtectedRoutes />
                                                            {contextHolder}
                                                       </CommonStates>
                                                  </Suspense>
                                             </RequireAuth>
                                        </Suspense>
                                   }
                              />
                         </Routes>
                    </Router>
               </MainContext.Provider>
          </AntdConfigProvider>
     )
}

export default App
