import { Auth } from 'aws-amplify'

export const getAccessToken = async (withPayload: any) => {
     const session: any = await Auth.currentSession()
     if (withPayload) {
          return session.idToken
     } else {
          return session.getIdToken().getJwtToken()
     }
}

export const isLoggedIn = async () => {
     try {
          await Auth.currentAuthenticatedUser()
          return true
     } catch (error) {
          return false
     }
}
