let progress = 0 // Variable to store count
const subscribers = new Set() // Set to store callback functions

const progressStore = {
     read() {
          // Method to get the count, this is basically getSnapshot method.
          return progress
     },
     // Subscribe method adds the "callback" to the "subscribers" set, and
     // return a method to unsubscribe from the store.
     subscribe(callback: any) {
          subscribers.add(callback)
          return () => subscribers.delete(callback)
     },
     // Method to increment the count
     startProgress(prog: number) {
          progress = prog
          subscribers.forEach((callback: any) => callback())
     },
     stopProgress() {
          progress = 0
          subscribers.forEach((callback: any) => callback())
     }
}

export default progressStore
