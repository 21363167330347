import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const antIcon = (
     <LoadingOutlined
          style={{
               fontSize: 30
          }}
          spin
     />
)

export default function index() {
     return (
          <div className="centerSpinnerForLazyComponents">
               <Spin indicator={antIcon} size="large" />
          </div>
     )
}
