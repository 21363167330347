//*  JOBSEEKER ENDPOINTS ---->*/

export const JOBSEEKER_APIS = Object.freeze({
     USER_PROFILE: `/user`,
     DELETE_USER: `/user`,
     SECTORS: `/sector`,
     WORK_DROPDOWN: `/dropdowns/work`,
     JOB_ROLES: `/jobrole`,
     INTERESTS: `/interest`,
     INTEREST_SKILL_DROPDOWN: `/dropdowns/interest-skills`,
     EDUCATION_DROPDOWNS: `/dropdowns/education`,
     PROFILE_GET: `/profile-completion/details`,
     HOME_SCREEN: `/homescreen`,
     HOME_JOB_SHOWROOM: (filter: string) => {
          let url = `/jobsearch/tags`
          if (filter) {
               url += `?filter=${filter}`
          }
          return url
     },
     NOTIFICATIONS: (page: number, limit: number) => `/notification/all-paginated?page=${page}&limit=${limit}`,
     WORK_DETAILS: `/work`,
     WORK_DETAILS_DELETE: (id: string) => `/work/${id}`,
     EDUCATION_DETAILS: `/education`,
     EDUCATION_DETAILS_DELETE: (id: string) => `/education/${id}`,
     AVAILABILITY: `/availability`,
     JOB_SEARCH_GET: (page: number, limit: number) => `/jobsearch/paginated?page=${page}&limit=${limit}`,
     JOB_SEARCH_GET_BY_ID: (id: string) => `/jobsearch/application/${id}`,
     JOB_CART: '/jobCart',
     JOB_APPLY: '/jobapply',
     SAVE_JOB: (id: string) => `/jobcart/${id}`,
     JOB_APPLY_DELETE: (id: string) => `/jobapply/${id}`,
     LIVE_APPLICATIONS: (page: number, limit: number) => `/appscreen/live-paginated?page=${page}&limit=${limit}`,
     LIVE_APPLICATION_FILTERS: `/appscreen/live/filters`,
     JOB_REPORT_FEEDBACK: `/report-feedback/save-feedback`,
     GET_FEEDBACK: `/report-feedback`,
     JOB_OFFERS_APPLICATION: (page: number, limit: number) =>
          `/appscreen/joboffer-paginated?page=${page}&limit=${limit}`,
     JOB_OFFERS_APPLICATION_FILTERS: `appscreen/joboffer/filters`,
     HISTORY_APPLICATION: (page: number, limit: number) => `/appscreen/history-paginated?page=${page}&limit=${limit}`,
     HISTORY_APPLICATION_FILTERS: `appscreen/history/filters`,
     APPLICATION_COUNT: `/appscreen/application-count`,
     JOB_APPLICATION_GET_BY_ID: (id: string) => `/jobsearch/application/${id}`,
     JOB_INTERVIEW_GET: (id: string) => `/job-interview/details/${id}`,
     JOB_INTERVIEW_POST: `/job-interview/submit`,
     SAVED_JOBS_GET: (page: number, limit: number) => `/jobcart/paginated-cart?page=${page}&limit=${limit}`,
     ACTION_FOR_JOB_OFFER: `/appscreen`,
     DELETE_NOTIFICATION: `/notification`,
     APPLICATION_VIEWS: (page: number, limit: number) => `/jobapply/views?page=${page}&limit=${limit}`
})

//*  EMPLOYER ENDPOINTS ---->*/

export const EMPLOYER_APIS = Object.freeze({
     EMPLOYER_HOME_SCREEN: `/portfolio-activity/getPortfolioActivity`,
     DELETE_EMP: `/employer/account`,
     EMP_ONGOING_JOBS: (activeKey: string, page: number, limit: number, search: string | undefined = undefined) => {
          let url = `/jobs/ongoing-jobs?sort=${activeKey}&page=${page}&limit=${limit}`
          if (search) {
               url += `&search=${search}`
          }
          return url
     },
     EMP_CLOSED_JOBS: (activeKey: string, page: number, limit: number, search: string | undefined = undefined) => {
          let url = `/jobs/closed-jobs?sort=${activeKey}&page=${page}&limit=${limit}`
          if (search) {
               url += `&search=${search}`
          }
          return url
     },
     EMP_SINGLE_JOB_BYID: (id: string) => `/jobs/job-details/${id}`,
     EMP_SINGLE_JOB_EDIT: (id: string) => `/jobs/${id}`,
     EMP_CLOSED_JOB_PATCH: (id: string) => `/jobs/CloseJobDate?jobId=${id}`,
     EMP_GET_QUESTIONS_FROM_QB: `/job-interview/question-bank`,
     EMP_ADD_TO_QUESTION_BANK: `/job-interview/question-bank/create`,
     EMP_EDIT_QUE_FROM_QB: (id: string) => `/job-interview/${id}`,
     EMP_DELETE_QUE_FROM_QB: (id: string) => `/job-interview/${id}`,
     EMP_JOB_CREATE: `/jobs/createJob`,
     EMPLOYER_PROFILE: `/employer/createEmployer`,
     EMP_PROFILE_GET: `/employer/getEmployerDetails`,
     EMP_SECTORS: '/employer/getSectors',
     EMP_CHECK_USER: '/auths/employer-info',
     PATCH_EMPLOYER_PROFILE: `/employer/updateEmployer`,
     EMP_APPLICATION_GET: (status: string, page: number, limit: number, sortKey: string) =>
          `/jobs/applications-jobs?applicationStatus=${status}&page=${page}&limit=${limit}&sort=${sortKey}`,
     GET_APPLIED_JOBSEEKERS: (
          id: string,
          status: string,
          page: number,
          limit: number,
          search: string | undefined = undefined,
          filter: string | undefined = undefined
     ) => {
          let url = `/jobs/job-applications?jobId=${id}&jobStatus=${status}&page=${page}&limit=${limit}`
          if (search) {
               url += `&search=${search}`
          }
          if (filter) {
               url += `&sort=${filter}`
          }
          return url
     },
     EMP_SINGLE_USER_DATA: (id: string) => `/jobs/application/${id}`,
     EMP_GET_VIDEO_INTERVIEW: (jobId: string, userId: string) =>
          `job-interview/details?jobId=${jobId}&userId=${userId}`,
     EMP_CHANGE_APPLICATION_STATUS: (id: string) => `/jobs/updateApplicantStatus?applicationId=${id}`,
     COMPANY_REG_NUMBER: (number: string) => `/employer/company/auto/info/${number}`,
     EMP_APPLICATIONS_COUNT: `/jobs/applications-count`,
     NOTIFICATIONS: (page: number, limit: number) => `/notifications/all-paginated?page=${page}&limit=${limit}`,
     DELETE_NOTIFICATION: `/notifications`,
     EMP_SUBSCRIPTION: `/subscription/packages`,
     EMP_CHECKOUT_SESSION: `/subscription/create-subscription/checkout-session`,
     EMP_DELETE_SUBSCRIPTION: (id: string) => `/subscription/cancel/${id}`,
     EMP_MEMBERSHIP: `/subscription/membership`,
     EMP_TRANSACTION_HISTORY: `/subscription/transaction-history`,
     EMP_EDIT_MEMBERSHIP: `/subscription/manage-subscription`,
     EMP_JOBS_AVAILABLE: `auths/employer-subscription`,
     EMP_CANCEL_SUBSCRIPTION_PAYMENT: `/subscription/payment/cancel`
})

export const HOME_SCREEN_VIDEO =
     'https://partimers-storage-bucket.s3.eu-west-2.amazonaws.com/hoescreen-video/partimer_intro.mp4'

export const fileUploaderURL = Object.freeze({
     path: `/upload/get-single-upload-url`
})
